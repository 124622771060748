<template>
	<div class="row" v-if="configEdit != null">
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body pb-2">
               <div class="row m-0">
                  <div class="col-12 px-1 mb-1">
                     <h6 class="font-18">Sociais</h6>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="fab fa-discord color-theme font-12 me-1"></i> URL Discord</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.urlDiscord" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="fab fa-tiktok color-theme font-12 me-1"></i> URL TikTok</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.urlTiktok" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="fab fa-youtube color-theme font-12 me-1"></i> URL YouTube</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.urlYoutube" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="fab fa-instagram color-theme font-12 me-1"></i> URL Instagram</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.urlInstagram" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="fab fa-facebook-f color-theme font-12 me-1"></i> URL Facebook</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.urlFacebook" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="fas fa-phone-alt color-theme font-12 me-1"></i> URL para contato</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.urlContato" maxlength="250" placeholder="URL para contato"></textarea>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body pb-2">
               <div class="row m-0">
                  <div class="col-12 px-1 mb-1">
                     <h6 class="font-18">Logos</h6>
                  </div>
                  <div class="col-md-6 px-1 mb-2">
                     <label><i class="fas fa-copyright color-theme font-12 me-1"></i> URL Logo Black (148x29)</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.logoBlack" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
                  <div class="col-md-6 px-1 mb-2">
                     <label><i class="far fa-copyright color-theme font-12 me-1"></i> URL Logo White (148x29)</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.logoWhite" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body pb-2">
               <div class="row m-0">
                  <div class="col-12 px-1 mb-1">
                     <h6 class="font-18">Banners</h6>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="far fa-image color-theme font-12 me-1"></i> URL Banner 1 (1200x525)</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.banner1" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="far fa-image color-theme font-12 me-1"></i> URL Banner 2 (1200x525)</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.banner2" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="far fa-image color-theme font-12 me-1"></i> URL Banner 3 (740x220)</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.banner3" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="far fa-image color-theme font-12 me-1"></i> URL Banner 4 (740x220)</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.banner4" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="far fa-image color-theme font-12 me-1"></i> URL Banner 5 (486x220)</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.banner5" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="far fa-image color-theme font-12 me-1"></i> URL Banner 6 (486x220)</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.banner6" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="far fa-image color-theme font-12 me-1"></i> URL Banner 7 (486x220)</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.banner7" maxlength="250" placeholder="URL Imagem"></textarea>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body pb-2">
               <div class="row m-0">
                  <div class="col-12 px-1 mb-1">
                     <h6 class="font-18">Descrições</h6>
                  </div>
                  <div class="col-md-6 px-1 mb-2">
                     <label><i class="fas fa-ellipsis-h color-theme font-12 me-1"></i> Descrição 1</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.descricao1" maxlength="250" placeholder="Descrição"></textarea>
                  </div>
                  <div class="col-md-6 px-1 mb-2">
                     <label><i class="fas fa-ellipsis-h color-theme font-12 me-1"></i> Descrição 2</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.descricao2" maxlength="250" placeholder="Descrição"></textarea>
                  </div>
                  <div class="col-md-6 px-1 mb-2">
                     <label><i class="fas fa-ellipsis-h color-theme font-12 me-1"></i> Descrição 3</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.descricao3" maxlength="250" placeholder="Descrição"></textarea>
                  </div>
                  <div class="col-md-6 px-1 mb-2">
                     <label><i class="fas fa-ellipsis-h color-theme font-12 me-1"></i> Descrição 4</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.descricao4" maxlength="250" placeholder="Descrição"></textarea>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body pb-2">
               <div class="row m-0">
                  <div class="col-12 px-1 mb-1">
                     <h6 class="font-18">Pagamentos
                        <i class="fal fa-question-circle color-theme font-16 float-end cursor-pointer" title="Links úteis" @click="linkPagamentos"></i>
                     </h6>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label class="w-100"><i class="far fa-lock color-theme font-12 me-1"></i> ApiKey PagHiper </label>
                     <textarea class="form-control shadow-none" v-model="configEdit.apiKeyPagHiper" maxlength="250" placeholder="ApiKey"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="far fa-lock color-theme font-12 me-1"></i> Token PagHiper</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.tokenPagHiper" maxlength="250" placeholder="Token"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="far fa-lock color-theme font-12 me-1"></i> Token MercadoPago</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.tokenMercadoPago" maxlength="250" placeholder="Token"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="far fa-lock color-theme font-12 me-1"></i> Client-Id PayPal</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.clientIdPayPal" maxlength="250" placeholder="Client-Id"></textarea>
                  </div>
                  <div class="col-md-6 col-xl-4 px-1 mb-2">
                     <label><i class="far fa-lock color-theme font-12 me-1"></i> Client-Secret PayPal</label>
                     <textarea class="form-control shadow-none" v-model="configEdit.clientSecretPaypal" maxlength="250" placeholder="Client-Secret"></textarea>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="rodape">
         <div class="card mb-0">
            <div class="card-body text-center p-2">
               <h6 class="font-15 px-3 mb-2 mt-1">Configurações</h6>
               <button class="btn btn-primary font-13" @click="saveConfig">
                  <i class="far fa-check me-1 font-11"></i> Salvar
               </button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'AdmConfigurações',
   data: function() {
		return {
			configEdit: null
		}
	},
   computed: {
		... mapState({
			dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest,
			config: state => state.config
		})
	},
   methods: {
		saveConfig : function () {
         let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'adminuser/saveconfig',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername,
					'Content-Type': 'application/json'
            },
				data: this.configEdit

         }).then(function () {
            ref.$toast.fire({
               icon: 'success',
               title: 'Configurações salvas!'
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
		},
      linkPagamentos : function () {
         Swal.fire({
            title: 'Links úteis',
            html: '<div class="d-block my-3"><a class="text-decoration-none weight-600 color-entrega" target="_blank" href="https://developer.paypal.com/developer/applications">Paypal</a></div>' +
               '<div class="d-block mb-3"><a class="text-decoration-none weight-600 color-entrega" target="_blank" href="https://www.mercadopago.com.br/developers/panel">MercadoPago</a></div>' +
               '<div class="d-block mb-3"><a class="text-decoration-none weight-600 color-entrega" target="_blank" href="https://www.paghiper.com/painel/credenciais/">PagHiper</a></div>',
            confirmButtonText: 'Fechar'
         })
      }
   },
   mounted() {
      this.configEdit = this.config
   }
}

// https://developer.paypal.com/developer/applications
// https://www.mercadopago.com.br/developers/panel
// https://www.paghiper.com/painel/credenciais/

</script>

<style scoped>

.rodape {
   padding: 0;
   position: fixed; 
   bottom: 45px; 
   right: 15px; 
   z-index: 5; 
   width: max-content; 
   border: 1px solid #ffffff1f;
}

.rodape button {
   height: auto !important;
}

</style>